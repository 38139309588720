import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/eoka_blog_header.png");
const section_1 = require("../../../assets/img/blogs/eoka_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/eoka_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/eoka_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/eoka_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/eoka_blog_img_5.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="How to use knowledge AI to create a self-service for employees"
        description=" Build end-to-end employee onboarding automation for an exceptional employee experience. Knowledge AI from Workativ is a go-to automation tool for your initiative. "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt eoka_blog_header_upt ${
              isMobile ? "align-items-center" : ""
            }`}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to use{" "}
                    <span
                      style={{ backgroundColor: "#8DFF7E" }}
                      className="blog-text-color-padding"
                    >
                      knowledge AI
                    </span>{" "}
                    to Improve
                    <span
                      style={{ backgroundColor: "#8DFF7E" }}
                      className="blog-text-color-padding"
                    >
                      Employee Onboarding
                    </span>{" "}
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to use
                    <br />
                    <span
                      style={{ backgroundColor: "#8DFF7E" }}
                      className="blog-text-color-padding"
                    >
                      knowledge AI
                    </span>
                    <br />
                    to Improve
                    <br />
                    <span
                      style={{ backgroundColor: "#8DFF7E" }}
                      className="blog-text-color-padding"
                    >
                      Employee Onboarding
                    </span>
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why is exceptional employee onboarding experience
                  essential?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What is employee onboarding automation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Mundane paperwork or documentation processes
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Make a great first impression on the first day of
                  onboarding.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Build Effective Training and development.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Allow your candidate to keep track of onboarding
                  information seamlessly.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Make asset assignment frictionless.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. Help update employee details in the HRIS system.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section9")}
                >
                  9. Collect Feedback for a more improved experience.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section10"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section10"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section10")}
                >
                  10. Collect Feedback for a more improved experience.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section11"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section11"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section11")}
                >
                  11. Employee onboarding best practices:
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section12"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section12"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section12")}
                >
                  12. Workativ — an all-in-one solution for employee onboarding
                  automation
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge is essential for everyone to work and be successful.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Human resources also need knowledge for their employees to
                satisfy their queries at every touchpoint, from hiring to
                onboarding and from meeting everyday queries to offboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Only hiring does not guarantee employee retention. A smooth
                onboarding can create a lasting impression and ensure employee
                engagement and loyalty.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The road to successful onboarding takes work. Most organizations
                follow manual processes or use tools too old to support the
                modern needs of employee onboarding.
              </p>
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Knowledge AI combines the power of a large language model to
                streamline every step of the onboarding journey till the
                employee feels comfortable in their roles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This Generative AI-backed platform — Knowledge AI, integrates
                many knowledge resources for employees and efficiently solves
                their queries by delivering summarized and contextual responses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI syncs with a conversational chat interface,
                providing the best experience with{" "}
                <a href="https://workativ.com/use-cases/employee-onboarding-automation#:~:text=Workativ%20provides%20a%20platform%20to,conversing%20with%20an%20HR%20chatbot.">
                  onboarding automation workflows,
                </a>{" "}
                streamlining HR processes, and allowing HR to handle employee
                onboarding frictionlessly.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why is exceptional employee onboarding experience essential?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Knowledge AI for exceptional employee onboarding 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The first day at the office is nothing less than intimidating
                for any employee.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As we all know, the primary objective of employee onboarding is
                to set an employer up for future success and growth through
                making an impactful employee onboarding experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is etched in every HR’s hiring strategy. Still, it does not
                work as planned.
              </p>

              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Employee turnover is a serious concern for most employers. This
                is quite prevalent post covid as work transitions to remote and
                hybrid settings.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The sole reason organizations can cite is ineffective employee
                onboarding experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                According to Gallup,{" "}
                <a href="https://www.gallup.com/workplace/247091/create-exceptional-onboarding-journey-new-employees.aspx?elqTrackId=7531fdfc3aa6433b9b71a5b3ca05a3bd&elq=e1964f63726b4e708548113e89e7e0fb&elqaid=1014&elqat=1&elqCampaignId=">
                  only 12% of employees agree
                </a>{" "}
                that their organizations put great effort into employee
                onboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, it also means that 88% of employers have a less
                effective onboarding program than expected.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Deep down the line, the study also focussed on why positive
                employee onboarding means so much for a company.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  A well-prepared onboarding plan can set employees up to handle
                  work to their full potential.
                </li>
                <li className="font-section-normal-text-testimonials">
                  A well-prepared onboarding plan can set employees up to handle
                  work to their full potential.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Onboarding needs to be consistent and dynamic. 29% of new
                  hires are likely to excel at their work if their onboarding
                  experience is exceptional.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Needless to say, most organizations follow the old practice of
                using old tools and platforms for onboarding.{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Employees value consistent experience, depriving them of it
                  can lead to employee turnover and impact the bottom line.
                </span>
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Knowledge AI helps overcome onboarding-related challenges. 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The effect starts showing up within the first year only.
                According to the Society of Human Resource Management, companies
                can expect employee turnover of as much as 50% for hourly
                workers in the first four months. In contrast, seniors exhibit
                the same rate of turnover in 18 months.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the same infrastructure for employee onboarding, the
                turnover cycle would come again.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a business owner, it costs you massive —
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Gallup says employees take 12 months to reach their fullest
                  potential with an onboarding program.
                </li>
                <li className="font-section-normal-text-testimonials">
                  SHRM says companies must bear the expenses of identifying,
                  hiring, and re-training, which is as high as 6-9 months of
                  salary of a replacement.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a whole, you suffer a loss of a replacement cost, including
                12 months of salary for a person in training.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a CHRO or an HR leader, it is highly significant that you
                consider the modernization of your employee onboarding process
                to support your strategy to streamline onboarding and drive
                long-term success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI, with its ability to perform NLP-based tasks, can
                help you{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  automate onboarding tasks{" "}
                </span>
                and alleviate the pain of employee disengagement and
                dissatisfaction.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is employee onboarding automation?
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="onboarding automation "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The process of reducing time and errors while streamlining
                employee onboarding tasks using AI/ML tools and{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  powerful automated workflows
                </span>{" "}
                is known as{" "}
                <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                  onboarding automation.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a nutshell, employee onboarding automation can eliminate the
                need for manual intervention in most of the critical processes
                of the onboarding journey, streamline every process, free up HR
                teams, and allow new hires to manage onboarding efficiently and
                autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A Knowledge AI-driven chatbot makes sures your employees get
                everything they need to excel in their roles and also get
                answers for every question they will gradually have.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI can help new hires with automated onboarding
                workflows to perform many common tasks:
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Help with paperwork
                </li>
                <li className="font-section-normal-text-testimonials">
                  Deliver a welcome email
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule invites for orientation calls
                </li>
                <li className="font-section-normal-text-testimonials">
                  Deliver information for workplace queries
                </li>
                <li className="font-section-normal-text-testimonials">
                  Assign assets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow to update HRIS systems
                </li>
                <li className="font-section-normal-text-testimonials">
                  Help adhere to workplace culture and policy
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Knowledge AI improves employee onboarding with automation—
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Onboarding automation 
 "
              />
              <h3 className="font-section-sub-header-small-home">
                Mundane paperwork or documentation processes
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is very usual to have employees handle much paperwork.
                Employees' first day can be overwhelming as they must fill in
                many papers or cross-check these documents for accuracy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Many onboarding documents can include,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Employment offer letter
                </li>
                <li className="font-section-normal-text-testimonials">
                  Identity and verification documents
                </li>
                <li className="font-section-normal-text-testimonials">
                  Experience certification
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agreement or contract papers
                </li>
                <li className="font-section-normal-text-testimonials">
                  Salary deposit form
                </li>
                <li className="font-section-normal-text-testimonials">
                  Health insurance document
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Manual processes require someone to guide the person and get
                things done actively. If a single piece of paperwork contains
                wrong information or empty fields, the work remains incomplete,
                requiring a person to be available to execute the pending tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a requirement that demands extra effort outside of daily
                work from HR and compensates for those missed productivity hours
                later. A backlog of HR operations can also occur.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                A new hire can feel too shy to connect with the right person
                while working with the documentation processes.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Performing documentation ahead of time with a modern onboarding
                platform can help to some extent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But, it is not a complete solution. If a candidate has queries
                or needs information about the steps to fill in or provides a
                digital signature, how does he get the proper guidance?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By giving the candidate early access to the company resources
                that explain how to perform documentation tasks with sample
                documents and steps, they can help essentially.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI can help build{" "}
                <span className="font-section-normal-text-testimonials color-black">
                  pre-onboarding workflows
                </span>{" "}
                and provide adequate information to perform the tasks
                independently.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  First, a new can have enough time to be comfortable doing the
                  work.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI underpins a conversational AI platform’s core
                  ability to answer anything about onboarding.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI can surface summarized information that is
                  contextual and intent-based.
                </li>
                <li className="font-section-normal-text-testimonials">
                  A specific question can have domain-specific answers, allowing
                  candidates to follow particular procedures to fill in
                  documents. So there are fewer mistakes, rework, and
                  third-party involvement.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI is customizable, allowing users to provide
                  articles to guide candidates with samples of previously filled
                  forms or documentation processes.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Make a great first impression on the first day of onboarding.
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Things need to improve for the new hire on the first day of
                onboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is apparent that a new hire must have a complete picture of
                how his new workplace looks, how his colleagues and department
                look forward to him, and how comfortably he knows the company
                culture.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Making arrangements for all of these processes manually is
                challenging.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a fast-paced workplace, availability is a challenge. No
                showup or delayed showup can impact his experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A long wait time can paint a picture of dissatisfaction in his
                mind. In addition, he needs a better sense of knowledge about
                his workplace.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <h3 className="font-section-sub-header-small-home">
                In a 2021 survey for Principles, 31% of employees said they
                struggled to connect with colleagues.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                If this continues, the first-day impression can be dreadfully
                wrong.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI makes it easy to set your employees up for a
                fantastic onboarding experience on the first day.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can use Knowledge AI to guide your candidate for several
                interaction sessions with your company's key stakeholders.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-16">
                <li className="font-section-normal-text-testimonials">
                  Allow Knowledge AI to wish your new hire with a welcome email.
                  It is a friendly gesture towards your candidate and makes him
                  feel comfortable.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send invitations for a face-to-face meeting with stakeholders
                  in your company.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-6 list-style-type-circle mb-16">
                <li className="font-section-normal-text-testimonials">
                  Set an introductory meeting with colleagues and help him get
                  familiar with his people.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Arrange a meeting with the department head for inspiration and
                  a morale boost.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Last but not least, Knowledge AI allows you to provide enough
                  resources for the new hire to learn and know about company
                  culture, people, and policy.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The easiest way to provide a connected experience on the first
                day is by giving him tools and resources through a
                conversational AI platform, Knowledge AI — without any manual
                intervention.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Build Effective Training and development.
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                The challenge is that onboarding can take 12 months for a
                candidate to become potential in his specific role and be ready
                to take ownership. This is quite a long time.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Generally, what causes a problem is that companies have
                materials for everything to learn about the company, its people,
                culture, and policy.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With so much to pay attention to, the focus on specific roles
                and responsibilities can start fading.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                One of many reasons is that the right person to train and help
                re-skill about a particular role can have other significant
                priorities, giving important calls a miss and baffling him with
                a series of questions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Above the cut, you can also miss out on the opportunity to track
                training and development progress.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say the new hire is a marketing consultant.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A hands-on training experience with access control to all the
                marketing tools, platforms, and resources that explain the usage
                of these tools can provide significant help.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Multiple domain-specific questions can help you follow your work
                process and culture. They can include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    How to use CRM platforms such as HubSpot to keep track of
                    customers for follow-up
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Where to keep marketing materials in Notion and how to
                    access them
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    What are the ways you can try to manage your project in
                    Asana
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>How to share documents with your teammates</span>
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18">
                There is so much more to help your candidate feel comfortable
                about his roles and responsibilities using Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI can help your candidate get the resources he
                requires to learn and know how specific processes work for your
                business.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can also create quizzes to test his proficiency in his role.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In addition to helping him learn, you can easily track his
                performance through Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Creating an account in your ITSM system for new hires and
                keeping track of a ticket through Knowledge AI-driven
                self-service chatbot can help monitor progress and provide
                suggestions for improvement.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Allow your candidate to keep track of onboarding information
                seamlessly.
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Companies have long felt the continuous need to supply the
                correct information to their new hires.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                During the onboarding training, a new candidate has much to
                learn.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The agony is most companies tend to provide too much information
                all at a time, causing information overload.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is a prevalent scenario across all boards. This neither
                helps him to learn nor allows him to apply it.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI makes information available around the clock.
                Whether product-specific or process-specific, a candidate can
                access information anytime.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                An organized list of plans is always practical to help him walk
                through necessary resources. But we must avoid putting so much
                pressure on the candidate.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI brings the flexibility of finding information
                easily just by typing a keyword and surfacing the document,
                which gives complete visibility into specific processes or
                procedures.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition, Knowledge AI also lessens the effort of finding
                essential information through a long article by providing
                summarized information.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Make asset assignment frictionless.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Picture this: a first day at work is so busy. A new hire must
                meet a couple of stakeholders, handle paperwork, and whatnot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Work can swiftly progress if a new hire can effectively access
                essential tools, regardless of remote or onsite placement.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is a typical scenario to wait for an IT team member to set
                configurations and other necessary IT tasks for a new candidate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In most cases, guiding a new hire in real-time is also
                frightening if the receiver needs more than standard time to
                understand the process.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is a hassle for everyone — a new hire, HR team, IT team.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/use-cases/employee-onboarding-automation#:~:text=Employee%20Onboarding%20made%20easy%20with%20your%20favorite%20apps&text=Workativ%20integrates%20with%20popular%20workplace,across%20all%20the%20apps%20seamlessly.">
                  {" "}
                  Onboarding automation
                </a>{" "}
                can help at large.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Knowledge AI working inside a conversational self-service
                chatbot, user app provisions can be easily automated.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A workflow is triggered as soon as a new hire’s name is added to
                the HRIS system, and a message is carried forward to the
                self-service for the manager’s approval for necessary apps.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the manager’s permission, a new hire can instantly access
                work-related apps in his self-service bot. Also, there are
                enough knowledge articles for a new hire to apply new
                credentials for app security.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Help update employee details in the HRIS system.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                An HR team remains busy with all those admin tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you follow the legacy approach to managing employee details,
                we can assume that employee details are not filled up on time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                There are a significant number of problems for employees if
                details are incomplete or wrongly filled in.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                During the onboarding period, a specific employee can face
                delays in getting salaries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A confirmation letter can contain missing information. Also,
                teams could have zero knowledge if a new hire goes on leave,
                impacting a certain project.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                All of these can add up to employee frustration.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using automated workflows that allow your new hires to
                self-update information in an HRIS system can significantly help
                provide a great employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service chatbot within your business comms channel can
                translate into a simple and efficient platform to provide all
                details in an HRIS system in an effortless manner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI also ensures your new hires can fetch adequate
                information to fill in details in an employee portal.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This reduces the chance of errors and provides correct
                information for HR or Finance teams, which further helps manage
                payroll, insurance, and other workplace programs.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Collect Feedback for a more improved experience.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is important to ask for employee feedback while they are on
                onboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a manual onboarding process, an HR team has fewer
                opportunities to connect with a new hire and become candid to
                know how they feel, what makes them uncomfortable at work, or
                what they need to work efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With busy schedules to handle regularly, HR misses this critical
                task. Unfortunately, you never know why a new hire chooses to
                jump ship in a very short time.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Knowledge AI
                </span>{" "}
                helps you automate the dispatch of feedback forms for you
                periodically.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service chatbot driven by Knowledge AI efficiencies can
                provide an integrated solution to help guide a user and provide
                his thoughts in a feedback form.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is easier for you to schedule the delivery of feedback forms
                at regular intervals, helping you capture important information
                about what your new hires are thinking about your company and
                culture.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Based on the feedback, you can implement changes and help a new
                hire adapt to your workplace.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section10">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of employee onboarding automation with Knowledge AI
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="employee onboarding benefits with Knowledge AI automation
 "
              />
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    End-to-end automation:{" "}
                  </span>
                  Many automation tools provide automation to some extent.
                  Knowledge AI can automate information delivery and onboarding
                  workflows and help improve end-to-end task management.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Elevated user experience:&nbsp;
                  </span>
                  New hires can feel more confident about asking questions. They
                  are more productive in solving problems without waiting for a
                  person.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Fewer turnover:{" "}
                  </span>
                  Knowledge AI provides accurate domain-specific information to
                  perform tasks with proper citation and consolidated
                  information. By helping them become independent in solving
                  problems, Knowledge AI provides adequate tools and data to
                  stay productive and complacent. This allows businesses to
                  retain employees for a long enough time for growth.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Cost-efficiency:{" "}
                  </span>
                  Knowledge AI provides accurate domain-specific information to
                  perform tasks with proper citation and consolidated
                  information. By helping them become independent in solving
                  problems, Knowledge AI provides adequate tools and data to
                  stay productive and complacent. This allows businesses to
                  retain employees for a long enough time for growth.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section11">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Employee onboarding best practices:
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Getting an automation platform can only help if you have the
                necessary resources and a proper strategy.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Evaluate the scope of automation for onboarding:&nbsp;
                  </span>
                  There are so many manual procedures for employee onboarding
                  that you need to automate. It is essential to determine which
                  steps you need to automate. It can comprise automating welcome
                  emails, app provisions, documentation, etc. You can implement
                  overall automation or automate some specific processes.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Prepare your onboarding workflows:&nbsp;
                  </span>
                  Optimizing an onboarding automation tool is an effective
                  solution for enhanced user experience. Invest enough time to
                  sort out procedures, customize your workflows well in advance,
                  and integrate them with your Knowledge AI platform.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Create knowledge resources for real-time and consolidated
                    information delivery:&nbsp;
                  </span>
                  To allow new hires to fetch information any time of the day,
                  it is significant that you have domain-specific knowledge
                  articles ready to feed your Knowledge AI model. These
                  knowledge articles can help you provide accurate information
                  and help them accomplish specific tasks accurately.
                  Additionally, an occasion revision to the knowledge articles
                  can help your people utilize the latest and precise
                  information that eliminates the workplace challenge in
                  knowledge delivery and reduces manual intervention.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Choose an end-to-end onboarding automation platform:&nbsp;
                  </span>
                  This is an essential criterion for integrating an efficient
                  onboarding automation platform with a business communications
                  channel. The objective is to make this platform easy for every
                  user and provide help instantly.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section12">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ — an all-in-one solution for employee onboarding
                automation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Working with a no-code platform is an easy iteration for anyone
                who wants to tame onboarding processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ promotes effective and powerful employee onboarding
                automation with Knowledge AI that improves the overall
                onboarding journey for businesses like yours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Workativ, there is no need to write a single line of code
                to create workflow automation. Yet, you can take control of all
                necessary processes through automation and deliver exceptional
                user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                While Knowledge AI best provides response accuracy,
                conversational AI chatbot makes it easy for your users to ask
                questions anytime they want and solve their onboarding queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With that, automated workflows can help streamline a dozen
                onboarding tasks, such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  User app provision
                </li>
                <li className="font-section-normal-text-testimonials">
                  Password reset
                </li>
                <li className="font-section-normal-text-testimonials">
                  Account unlock
                </li>
                <li className="font-section-normal-text-testimonials">
                  Leave management
                </li>
                <li className="font-section-normal-text-testimonials">
                  PTP queries
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is customizable. As your business demands, you can create as
                many domain-specific workflows to automate onboarding and
                deliver exceptional user experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                You can look at our onboarding automation webinar for more
                clarity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.youtube.com/watch?v=jVUaY3rmTaw">
                  Employee Onboarding Automation
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Note: Knowledge AI is a feature added to the conversational AI
                platform. Existing or new users can enable Knowledge AI for
                automated workflows and harness the benefits of large language
                models for generating accurate answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Are you interested to know more about Knowledge AI and its
                application to automate employee onboarding?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why is exceptional employee onboarding experience
                    essential?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What is employee onboarding automation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Mundane paperwork or documentation processes
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Make a great first impression on the first day of
                    onboarding.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Build Effective Training and development.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Allow your candidate to keep track of onboarding
                    information seamlessly.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Make asset assignment frictionless.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. Help update employee details in the HRIS system.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section9")}
                  >
                    9. Collect Feedback for a more improved experience.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section10"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section10"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section10")}
                  >
                    10. Collect Feedback for a more improved experience.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section11"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section11"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section11")}
                  >
                    11. Employee onboarding best practices:
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section12"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section12"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section12")}
                  >
                    12. Workativ — an all-in-one solution for employee
                    onboarding automation
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge is essential for everyone to work and be successful.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Human resources also need knowledge for their employees to
                satisfy their queries at every touchpoint, from hiring to
                onboarding and from meeting everyday queries to offboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Only hiring does not guarantee employee retention. A smooth
                onboarding can create a lasting impression and ensure employee
                engagement and loyalty.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The road to successful onboarding takes work. Most organizations
                follow manual processes or use tools too old to support the
                modern needs of employee onboarding.
              </p>
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Knowledge AI combines the power of a large language model to
                streamline every step of the onboarding journey till the
                employee feels comfortable in their roles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This Generative AI-backed platform — Knowledge AI, integrates
                many knowledge resources for employees and efficiently solves
                their queries by delivering summarized and contextual responses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI syncs with a conversational chat interface,
                providing the best experience with{" "}
                <a href="https://workativ.com/use-cases/employee-onboarding-automation#:~:text=Workativ%20provides%20a%20platform%20to,conversing%20with%20an%20HR%20chatbot.">
                  onboarding automation workflows,
                </a>{" "}
                streamlining HR processes, and allowing HR to handle employee
                onboarding frictionlessly.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why is exceptional employee onboarding experience essential?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Knowledge AI for exceptional employee onboarding 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The first day at the office is nothing less than intimidating
                for any employee.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As we all know, the primary objective of employee onboarding is
                to set an employer up for future success and growth through
                making an impactful employee onboarding experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is etched in every HR’s hiring strategy. Still, it does not
                work as planned.
              </p>

              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Employee turnover is a serious concern for most employers. This
                is quite prevalent post covid as work transitions to remote and
                hybrid settings.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The sole reason organizations can cite is ineffective employee
                onboarding experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                According to Gallup,{" "}
                <a href="https://www.gallup.com/workplace/247091/create-exceptional-onboarding-journey-new-employees.aspx?elqTrackId=7531fdfc3aa6433b9b71a5b3ca05a3bd&elq=e1964f63726b4e708548113e89e7e0fb&elqaid=1014&elqat=1&elqCampaignId=">
                  only 12% of employees agree
                </a>{" "}
                that their organizations put great effort into employee
                onboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, it also means that 88% of employers have a less
                effective onboarding program than expected.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Deep down the line, the study also focussed on why positive
                employee onboarding means so much for a company.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  A well-prepared onboarding plan can set employees up to handle
                  work to their full potential.
                </li>
                <li className="font-section-normal-text-testimonials">
                  A well-prepared onboarding plan can set employees up to handle
                  work to their full potential.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Onboarding needs to be consistent and dynamic. 29% of new
                  hires are likely to excel at their work if their onboarding
                  experience is exceptional.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Needless to say, most organizations follow the old practice of
                using old tools and platforms for onboarding.{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Employees value consistent experience, depriving them of it
                  can lead to employee turnover and impact the bottom line.
                </span>
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Knowledge AI helps overcome onboarding-related challenges. 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The effect starts showing up within the first year only.
                According to the Society of Human Resource Management, companies
                can expect employee turnover of as much as 50% for hourly
                workers in the first four months. In contrast, seniors exhibit
                the same rate of turnover in 18 months.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the same infrastructure for employee onboarding, the
                turnover cycle would come again.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a business owner, it costs you massive —
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Gallup says employees take 12 months to reach their fullest
                  potential with an onboarding program.
                </li>
                <li className="font-section-normal-text-testimonials">
                  SHRM says companies must bear the expenses of identifying,
                  hiring, and re-training, which is as high as 6-9 months of
                  salary of a replacement.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a whole, you suffer a loss of a replacement cost, including
                12 months of salary for a person in training.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a CHRO or an HR leader, it is highly significant that you
                consider the modernization of your employee onboarding process
                to support your strategy to streamline onboarding and drive
                long-term success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI, with its ability to perform NLP-based tasks, can
                help you{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  automate onboarding tasks{" "}
                </span>
                and alleviate the pain of employee disengagement and
                dissatisfaction.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is employee onboarding automation?
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="onboarding automation "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The process of reducing time and errors while streamlining
                employee onboarding tasks using AI/ML tools and{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  powerful automated workflows
                </span>{" "}
                is known as{" "}
                <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                  onboarding automation.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a nutshell, employee onboarding automation can eliminate the
                need for manual intervention in most of the critical processes
                of the onboarding journey, streamline every process, free up HR
                teams, and allow new hires to manage onboarding efficiently and
                autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A Knowledge AI-driven chatbot makes sures your employees get
                everything they need to excel in their roles and also get
                answers for every question they will gradually have.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI can help new hires with automated onboarding
                workflows to perform many common tasks:
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Help with paperwork
                </li>
                <li className="font-section-normal-text-testimonials">
                  Deliver a welcome email
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule invites for orientation calls
                </li>
                <li className="font-section-normal-text-testimonials">
                  Deliver information for workplace queries
                </li>
                <li className="font-section-normal-text-testimonials">
                  Assign assets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow to update HRIS systems
                </li>
                <li className="font-section-normal-text-testimonials">
                  Help adhere to workplace culture and policy
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Knowledge AI improves employee onboarding with automation—
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Onboarding automation 
 "
              />
              <h3 className="font-section-sub-header-small-home">
                Mundane paperwork or documentation processes
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is very usual to have employees handle much paperwork.
                Employees' first day can be overwhelming as they must fill in
                many papers or cross-check these documents for accuracy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Many onboarding documents can include,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Employment offer letter
                </li>
                <li className="font-section-normal-text-testimonials">
                  Identity and verification documents
                </li>
                <li className="font-section-normal-text-testimonials">
                  Experience certification
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agreement or contract papers
                </li>
                <li className="font-section-normal-text-testimonials">
                  Salary deposit form
                </li>
                <li className="font-section-normal-text-testimonials">
                  Health insurance document
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Manual processes require someone to guide the person and get
                things done actively. If a single piece of paperwork contains
                wrong information or empty fields, the work remains incomplete,
                requiring a person to be available to execute the pending tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a requirement that demands extra effort outside of daily
                work from HR and compensates for those missed productivity hours
                later. A backlog of HR operations can also occur.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                A new hire can feel too shy to connect with the right person
                while working with the documentation processes.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Performing documentation ahead of time with a modern onboarding
                platform can help to some extent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But, it is not a complete solution. If a candidate has queries
                or needs information about the steps to fill in or provides a
                digital signature, how does he get the proper guidance?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By giving the candidate early access to the company resources
                that explain how to perform documentation tasks with sample
                documents and steps, they can help essentially.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI can help build{" "}
                <span className="font-section-normal-text-testimonials color-black">
                  pre-onboarding workflows
                </span>{" "}
                and provide adequate information to perform the tasks
                independently.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  First, a new can have enough time to be comfortable doing the
                  work.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI underpins a conversational AI platform’s core
                  ability to answer anything about onboarding.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI can surface summarized information that is
                  contextual and intent-based.
                </li>
                <li className="font-section-normal-text-testimonials">
                  A specific question can have domain-specific answers, allowing
                  candidates to follow particular procedures to fill in
                  documents. So there are fewer mistakes, rework, and
                  third-party involvement.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI is customizable, allowing users to provide
                  articles to guide candidates with samples of previously filled
                  forms or documentation processes.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Make a great first impression on the first day of onboarding.
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Things need to improve for the new hire on the first day of
                onboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is apparent that a new hire must have a complete picture of
                how his new workplace looks, how his colleagues and department
                look forward to him, and how comfortably he knows the company
                culture.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Making arrangements for all of these processes manually is
                challenging.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a fast-paced workplace, availability is a challenge. No
                showup or delayed showup can impact his experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A long wait time can paint a picture of dissatisfaction in his
                mind. In addition, he needs a better sense of knowledge about
                his workplace.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <h3 className="font-section-sub-header-small-home">
                In a 2021 survey for Principles, 31% of employees said they
                struggled to connect with colleagues.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                If this continues, the first-day impression can be dreadfully
                wrong.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI makes it easy to set your employees up for a
                fantastic onboarding experience on the first day.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can use Knowledge AI to guide your candidate for several
                interaction sessions with your company's key stakeholders.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-16">
                <li className="font-section-normal-text-testimonials">
                  Allow Knowledge AI to wish your new hire with a welcome email.
                  It is a friendly gesture towards your candidate and makes him
                  feel comfortable.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send invitations for a face-to-face meeting with stakeholders
                  in your company.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-6 list-style-type-circle mb-16">
                <li className="font-section-normal-text-testimonials">
                  Set an introductory meeting with colleagues and help him get
                  familiar with his people.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Arrange a meeting with the department head for inspiration and
                  a morale boost.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Last but not least, Knowledge AI allows you to provide enough
                  resources for the new hire to learn and know about company
                  culture, people, and policy.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The easiest way to provide a connected experience on the first
                day is by giving him tools and resources through a
                conversational AI platform, Knowledge AI — without any manual
                intervention.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Build Effective Training and development.
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                The challenge is that onboarding can take 12 months for a
                candidate to become potential in his specific role and be ready
                to take ownership. This is quite a long time.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Generally, what causes a problem is that companies have
                materials for everything to learn about the company, its people,
                culture, and policy.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With so much to pay attention to, the focus on specific roles
                and responsibilities can start fading.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                One of many reasons is that the right person to train and help
                re-skill about a particular role can have other significant
                priorities, giving important calls a miss and baffling him with
                a series of questions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Above the cut, you can also miss out on the opportunity to track
                training and development progress.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say the new hire is a marketing consultant.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A hands-on training experience with access control to all the
                marketing tools, platforms, and resources that explain the usage
                of these tools can provide significant help.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Multiple domain-specific questions can help you follow your work
                process and culture. They can include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    How to use CRM platforms such as HubSpot to keep track of
                    customers for follow-up
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Where to keep marketing materials in Notion and how to
                    access them
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    What are the ways you can try to manage your project in
                    Asana
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>How to share documents with your teammates</span>
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18">
                There is so much more to help your candidate feel comfortable
                about his roles and responsibilities using Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI can help your candidate get the resources he
                requires to learn and know how specific processes work for your
                business.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can also create quizzes to test his proficiency in his role.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In addition to helping him learn, you can easily track his
                performance through Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Creating an account in your ITSM system for new hires and
                keeping track of a ticket through Knowledge AI-driven
                self-service chatbot can help monitor progress and provide
                suggestions for improvement.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Allow your candidate to keep track of onboarding information
                seamlessly.
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Companies have long felt the continuous need to supply the
                correct information to their new hires.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                During the onboarding training, a new candidate has much to
                learn.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The agony is most companies tend to provide too much information
                all at a time, causing information overload.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is a prevalent scenario across all boards. This neither
                helps him to learn nor allows him to apply it.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI makes information available around the clock.
                Whether product-specific or process-specific, a candidate can
                access information anytime.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                An organized list of plans is always practical to help him walk
                through necessary resources. But we must avoid putting so much
                pressure on the candidate.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI brings the flexibility of finding information
                easily just by typing a keyword and surfacing the document,
                which gives complete visibility into specific processes or
                procedures.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition, Knowledge AI also lessens the effort of finding
                essential information through a long article by providing
                summarized information.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Make asset assignment frictionless.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Picture this: a first day at work is so busy. A new hire must
                meet a couple of stakeholders, handle paperwork, and whatnot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Work can swiftly progress if a new hire can effectively access
                essential tools, regardless of remote or onsite placement.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is a typical scenario to wait for an IT team member to set
                configurations and other necessary IT tasks for a new candidate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In most cases, guiding a new hire in real-time is also
                frightening if the receiver needs more than standard time to
                understand the process.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is a hassle for everyone — a new hire, HR team, IT team.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/use-cases/employee-onboarding-automation#:~:text=Employee%20Onboarding%20made%20easy%20with%20your%20favorite%20apps&text=Workativ%20integrates%20with%20popular%20workplace,across%20all%20the%20apps%20seamlessly.">
                  {" "}
                  Onboarding automation
                </a>{" "}
                can help at large.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Knowledge AI working inside a conversational self-service
                chatbot, user app provisions can be easily automated.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A workflow is triggered as soon as a new hire’s name is added to
                the HRIS system, and a message is carried forward to the
                self-service for the manager’s approval for necessary apps.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the manager’s permission, a new hire can instantly access
                work-related apps in his self-service bot. Also, there are
                enough knowledge articles for a new hire to apply new
                credentials for app security.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Help update employee details in the HRIS system.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                An HR team remains busy with all those admin tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you follow the legacy approach to managing employee details,
                we can assume that employee details are not filled up on time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                There are a significant number of problems for employees if
                details are incomplete or wrongly filled in.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                During the onboarding period, a specific employee can face
                delays in getting salaries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A confirmation letter can contain missing information. Also,
                teams could have zero knowledge if a new hire goes on leave,
                impacting a certain project.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                All of these can add up to employee frustration.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using automated workflows that allow your new hires to
                self-update information in an HRIS system can significantly help
                provide a great employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service chatbot within your business comms channel can
                translate into a simple and efficient platform to provide all
                details in an HRIS system in an effortless manner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI also ensures your new hires can fetch adequate
                information to fill in details in an employee portal.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This reduces the chance of errors and provides correct
                information for HR or Finance teams, which further helps manage
                payroll, insurance, and other workplace programs.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Collect Feedback for a more improved experience.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is important to ask for employee feedback while they are on
                onboarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a manual onboarding process, an HR team has fewer
                opportunities to connect with a new hire and become candid to
                know how they feel, what makes them uncomfortable at work, or
                what they need to work efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With busy schedules to handle regularly, HR misses this critical
                task. Unfortunately, you never know why a new hire chooses to
                jump ship in a very short time.
              </p>
              <h4 className="font-section-normal-text-medium">The Solution:</h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Knowledge AI
                </span>{" "}
                helps you automate the dispatch of feedback forms for you
                periodically.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service chatbot driven by Knowledge AI efficiencies can
                provide an integrated solution to help guide a user and provide
                his thoughts in a feedback form.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is easier for you to schedule the delivery of feedback forms
                at regular intervals, helping you capture important information
                about what your new hires are thinking about your company and
                culture.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Based on the feedback, you can implement changes and help a new
                hire adapt to your workplace.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section10">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of employee onboarding automation with Knowledge AI
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="employee onboarding benefits with Knowledge AI automation
 "
              />
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    End-to-end automation:{" "}
                  </span>
                  Many automation tools provide automation to some extent.
                  Knowledge AI can automate information delivery and onboarding
                  workflows and help improve end-to-end task management.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Elevated user experience:&nbsp;
                  </span>
                  New hires can feel more confident about asking questions. They
                  are more productive in solving problems without waiting for a
                  person.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Fewer turnover:{" "}
                  </span>
                  Knowledge AI provides accurate domain-specific information to
                  perform tasks with proper citation and consolidated
                  information. By helping them become independent in solving
                  problems, Knowledge AI provides adequate tools and data to
                  stay productive and complacent. This allows businesses to
                  retain employees for a long enough time for growth.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Cost-efficiency:{" "}
                  </span>
                  Knowledge AI provides accurate domain-specific information to
                  perform tasks with proper citation and consolidated
                  information. By helping them become independent in solving
                  problems, Knowledge AI provides adequate tools and data to
                  stay productive and complacent. This allows businesses to
                  retain employees for a long enough time for growth.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section11">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Employee onboarding best practices:
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Getting an automation platform can only help if you have the
                necessary resources and a proper strategy.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Evaluate the scope of automation for onboarding:&nbsp;
                  </span>
                  There are so many manual procedures for employee onboarding
                  that you need to automate. It is essential to determine which
                  steps you need to automate. It can comprise automating welcome
                  emails, app provisions, documentation, etc. You can implement
                  overall automation or automate some specific processes.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Prepare your onboarding workflows:&nbsp;
                  </span>
                  Optimizing an onboarding automation tool is an effective
                  solution for enhanced user experience. Invest enough time to
                  sort out procedures, customize your workflows well in advance,
                  and integrate them with your Knowledge AI platform.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Create knowledge resources for real-time and consolidated
                    information delivery:&nbsp;
                  </span>
                  To allow new hires to fetch information any time of the day,
                  it is significant that you have domain-specific knowledge
                  articles ready to feed your Knowledge AI model. These
                  knowledge articles can help you provide accurate information
                  and help them accomplish specific tasks accurately.
                  Additionally, an occasion revision to the knowledge articles
                  can help your people utilize the latest and precise
                  information that eliminates the workplace challenge in
                  knowledge delivery and reduces manual intervention.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Choose an end-to-end onboarding automation platform:&nbsp;
                  </span>
                  This is an essential criterion for integrating an efficient
                  onboarding automation platform with a business communications
                  channel. The objective is to make this platform easy for every
                  user and provide help instantly.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section12">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ — an all-in-one solution for employee onboarding
                automation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Working with a no-code platform is an easy iteration for anyone
                who wants to tame onboarding processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ promotes effective and powerful employee onboarding
                automation with Knowledge AI that improves the overall
                onboarding journey for businesses like yours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Workativ, there is no need to write a single line of code
                to create workflow automation. Yet, you can take control of all
                necessary processes through automation and deliver exceptional
                user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                While Knowledge AI best provides response accuracy,
                conversational AI chatbot makes it easy for your users to ask
                questions anytime they want and solve their onboarding queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With that, automated workflows can help streamline a dozen
                onboarding tasks, such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  User app provision
                </li>
                <li className="font-section-normal-text-testimonials">
                  Password reset
                </li>
                <li className="font-section-normal-text-testimonials">
                  Account unlock
                </li>
                <li className="font-section-normal-text-testimonials">
                  Leave management
                </li>
                <li className="font-section-normal-text-testimonials">
                  PTP queries
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is customizable. As your business demands, you can create as
                many domain-specific workflows to automate onboarding and
                deliver exceptional user experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                You can look at our onboarding automation webinar for more
                clarity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.youtube.com/watch?v=jVUaY3rmTaw">
                  Employee Onboarding Automation
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Note: Knowledge AI is a feature added to the conversational AI
                platform. Existing or new users can enable Knowledge AI for
                automated workflows and harness the benefits of large language
                models for generating accurate answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Are you interested to know more about Knowledge AI and its
                application to automate employee onboarding?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk"
              className="font-section-normal-text-testimonials"
            >
              Reactive to Proactive: Leveraging Generative AI for Smarter
              Employee Service Desks
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-save-itsm-cost"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Top 5 Ways CXOs Can Save ITSM Cost Using Generative AI
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
